import React, {RefObject, useState, useReducer, useRef, useCallback, useEffect } from "react";
import Image from 'next/image'

import { Workshop } from '@tilnet/lagaat-domain'
import { h3, buttonWhiteOrange } from '../../lib/tailwind/styles'
import { classnames } from "tailwindcss-classnames";

import scrollToRef from '../../lib/scrollToRef'

import bounce from '../../lib/bounce'

type WorkshopProps = Workshop & {
    contactRef: RefObject<HTMLDivElement>
}

const WorkshopItem = (props: WorkshopProps) => {
    const { thumbnail, title, excerpt, duration, price, contactRef} = props
    const [ isTextExpanded, toggleIsTextExpande ] = useReducer(isTextExpanded => !isTextExpanded, false)

    const rootRef = useRef<HTMLDivElement>(null)
    const titleRef = useRef<HTMLHeadingElement>(null)

    const [scrollHeight, setScrollHeight] = useState("")

    const getScrollHeight = useCallback(
        () => {
            if (!titleRef?.current || !rootRef?.current) {
                return "calc(100% - 60px)";
            }

            const titleStyle = getComputedStyle(titleRef.current)
            const rootStyle = getComputedStyle(rootRef.current)

            return (`calc(100% - ${
              titleRef.current.clientHeight
              + parseInt(titleStyle.marginTop)
              + parseInt(titleStyle.marginBottom)
              + parseInt(rootStyle.paddingTop)
            }px`)
        },
        [titleRef, rootRef]
    )

    useEffect(
        () => {
            const newHeight = getScrollHeight();
            if (!scrollHeight || scrollHeight !== newHeight) {
                setScrollHeight(newHeight)
            }

        },
        [scrollHeight, getScrollHeight, setScrollHeight]
    )

    useEffect(

        ()=>{
            const handleClickOutside =  function (this: Document, e: Event): any  {
                if (
                    !rootRef.current 
                    || rootRef.current.contains(e.target as Node)
                    || !isTextExpanded
                ) {
                    return;
                }

                toggleIsTextExpande()
            } 
            
            document?.addEventListener('mousedown', handleClickOutside ) 
            document?.addEventListener('click', handleClickOutside)

            return () => {
                document?.removeEventListener('mousedown', handleClickOutside)
                document?.removeEventListener('click', handleClickOutside)
            }
        },
        [rootRef, isTextExpanded]
    )
    
    return (
        <div 
            ref={rootRef} 
            onClick={toggleIsTextExpande} 
            className={`px-10px ${classnames(
                "w-6/12",
                "sm:w-3/12", 
                "text-center",
                "sm:px-3",
                "md:px-4",
                "xl:px-8",
                "lg:py-3",
                "flex-grow",
                "cursor-pointer"
                )}`
            }>

            <div className="flip">

                <div 
                    className={`flip-inner transition duration-700 ease-in-out ${isTextExpanded ? "flip-inner-flipped" : ""}`}
                >

                    <div className="flip-front">
                        {thumbnail?.src && 
                            <Image {...thumbnail} />
                        }
                        <h3 className={classnames(
                            h3,
                            "lg:mt-3",
                        )}>{title}</h3>
                    </div>
                    <div 
                        className={`flip-back bg-orange-400 rounded-lg px-1 md:px-2 py-3 lg:p-4 xl:p-5 text-white`}
                    >
                        <h3 ref={titleRef} className={classnames(h3,'mb-4', 'mt-2')}>{title}</h3>
                        <div 
                            className={`h-full overflow-y-scroll scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200`}
                            style={{height: scrollHeight}}
                        >
                            <div className='text-white md:px-4 lg:px-6'>
                                <p>{excerpt}</p>
                                <div className="hidden lg:flex my-4 justify-center">
                                    <a 
                                        href="#" 
                                        title='לפרטים והרשמה' 
                                        onClick={scrollToRef(contactRef)}
                                        className={classnames(buttonWhiteOrange, "mt-4")} 
                                    >
                                        לפרטים והרשמה
                                    </a>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkshopItem;