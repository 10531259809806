
import { classnames } from "tailwindcss-classnames"
export const pagePadding = classnames(
    "px-5", 
    "sm:px-6",
    "md:px-8",
    "xl:px-16",
)
export const sectionMargin = classnames(
    "mt-20",
    "md:mt-20",
    "lg:mt-32",
    "xl:mt-40",
    "2xl:mt-48",
)
export const h2 = classnames(
    "text-2xl",
    "sm:text-3xl",
    "lg:text-4xl",
    "leading-8",
    "sm:leading-9", 
    "lg:leading-10",
    "font-bold"
)
export const h3 = classnames(
    "text-lg",
    "sm:text-xl",
    "lg:text-2xl", 
    "leading-5",
    "sm:leading-6",
    "md:leading-7",
    "lg:leading-8",
    'xl:leading-9',
    "2xl:leading-10",
    "font-bold"
)
export const button = classnames(
    "flex",
    "justify-center",
    "items-center", 
    "w-32",
    "sm:w-40",
    "h-9",
    "sm:h-11",
    "border-2", 
    "px-1",
    "py-1", 
    "rounded-3xl", 
    "font-medium",
    "leading-none"
)
export const buttonOrange = classnames(button, "bg-yellow-400", "hover:bg-yellow-500","border-yellow-400", "hover:border-yellow-500","text-white")
export const buttonWhite = classnames(button,"bg-white")
export const buttonWhiteOrange = classnames(buttonWhite, "border-yellow-400","text-yellow-400", "hover:bg-yellow-400", "hover:text-white")
export const buttonWhiteBlue = classnames(buttonWhite, "border-blue-400","text-blue-400")
