import { useRef} from 'react'
import { SectionsRefs} from '@tilnet/lagaat-domain'

const useSectionsRef = (): SectionsRefs => ({
    therapy: useRef<HTMLDivElement>(null),
    workshops: useRef<HTMLDivElement>(null),
    blog: useRef<HTMLDivElement>(null),
    gallery: useRef<HTMLDivElement>(null),
    about: useRef<HTMLDivElement>(null),
    reviews: useRef<HTMLDivElement>(null),
    contact: useRef<HTMLDivElement>(null)
})

export default useSectionsRef
