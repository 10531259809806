import {useState, useEffect, useCallback } from 'react'
import SCREENS from './screens'
import bounce from '../bounce'

type ResponsiveLayout = {
    isSm: boolean,
    isMd: boolean,
    isLg: boolean,
}

type IsSizeArgs = keyof typeof SCREENS


const isSize = (size: IsSizeArgs): boolean =>  (
    'undefined' === typeof window || window.innerWidth >= parseFloat(SCREENS[size])
        ?   true
        :   false    
)

const useReponsiveLayout = (): ResponsiveLayout => {

    const [responsiveLayout, setResponsiveLayout] = useState<ResponsiveLayout>({
        isSm: true,
        isMd: true,
        isLg: true
    })

    const checkForWindow = 'undefined' === typeof window
        ?   undefined
        :   window

    const handleResize = useCallback(
        () => bounce( () => {
            setResponsiveLayout({
                isSm: isSize("sm"),
                isMd: isSize('md'),
                isLg: isSize('lg')
            })
        }),
        [] 
    )
    
    useEffect(()=>{
        handleResize()
        checkForWindow && window.addEventListener("resize", handleResize)

        return () => {
            checkForWindow && window.removeEventListener("resize", handleResize)
        }
    },
    [checkForWindow, handleResize]
    )
    
    return responsiveLayout
}

export default useReponsiveLayout
