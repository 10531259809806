import React, { RefObject } from "react";
import { classnames } from "tailwindcss-classnames";

import { Post} from '@tilnet/domain'
import { Section, PostItem, H2 } from "..";
import {buttonOrange, sectionMargin} from '../../lib/tailwind/styles'


const BlogSection = ({posts, blogRef}: {posts: Post[], blogRef: RefObject<HTMLDivElement>}) => {
    return (
        <Section className={`blog-section w-379 md:max-w-880 2xl:max-w-screen-xl1/2 2xl:mx-auto ${classnames(
            sectionMargin,
            "px-5",
            "sm:px-3",
            "xl:px-8",
            "max-w-full",
            "mx-auto",
            "sm:max-w-2xl",
            "lg:max-w-full",
            "sm:w-full",
        )}`} sectionRef={blogRef} key="blog">
            <H2 className={classnames("items-center", "mb-6")} title="פשוט ליצור" />
            {posts && 
                <div className="flex flex-wrap">
                    { posts.map((post, key) => <PostItem key={key} {...post} />)}
                </div>
            }
            {/* <button className={classnames(buttonOrange,"mx-auto","mt-3","md:mt-5")}>לפוסטים נוספים</button> */}
        </Section>
    )
}

export default BlogSection