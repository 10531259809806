
import dynamic from 'next/dynamic'
import { Menu as MenuInf } from '@tilnet/lagaat-domain'
import useReponsiveLayout from '../../lib/tailwind/useResponsiveLayout'

const BaseMenu = dynamic(()=>import('./BaseMenu'))
const MobileMenu = dynamic(()=>import('./MobileMenu'))

const MainMenu = (menu: MenuInf ) => {

    const {isLg} = useReponsiveLayout()

    return (
        <>
            {isLg && 
                <BaseMenu {...menu} />
            }
            {!isLg &&
                <MobileMenu {...menu} />
            }
        </>
    )
}

export default MainMenu