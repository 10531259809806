import React, { RefObject} from "react";
import { classnames } from "tailwindcss-classnames";

import { Workshop as WorkshopInf } from "@tilnet/lagaat-domain";
import { Workshop, Section, H2 } from "..";
import { sectionMargin } from '../../lib/tailwind/styles'

type SectionProps = {
    workshops: WorkshopInf[]
    workshopsRef: RefObject<HTMLDivElement>
    contactRef: RefObject<HTMLDivElement>
}

const WorkshopSection = ({workshops, workshopsRef, contactRef}: SectionProps) => {
    return (
    
    <Section className={`2xl:max-w-screen-xl1/2 px-10px ${classnames(
        sectionMargin,
        "px-5", 
        "2xl:mx-auto",
        "sm:px-3",
        "md:px-4",
        "xl:px-8",
    )}`} sectionRef={workshopsRef} key="workshops">
            <H2 className={classnames("items-center", "pt-6",'pb-3')} title="סדנאות" />

            {workshops && 
                <div className="flex flex-wrap justify-around gap-y-3 sm:gap-y-4 lg:gap-y-5">
                    { workshops.map((workshop, key) => <Workshop key={key} {...workshop} contactRef={contactRef} />)}
                </div>
            }
        </Section>
    )
}

export default WorkshopSection