import React from "react";

type Props = {
    fill?: string,
    className?: string
}

function Icon({fill, className}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="8"
      fill="none"
      viewBox="0 0 60 8"
      className={className || ""}
    >
      <path
        fill={fill || "#141414"}
        d="M4.326 6.76c8.497-2.074 17.269-1.225 25.818-.295 8.929.964 17.858 2.09 26.814 1.241 1.31-.114 3.16-1.24 3.041-3.15-.118-1.911-2.268-2.466-3.487-2.352-8.982.85-17.964-.539-26.906-1.47-9.178-.946-18.357-1.273-27.443.931C.852 1.975-.46 3.657.157 5.34c.616 1.681 2.871 1.73 4.17 1.42z"
      ></path>
    </svg>
  );
}

export default Icon;
