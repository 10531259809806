import React, { useRef } from "react";
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { classnames } from "tailwindcss-classnames"

import logo from '../../../public/deco-logo.png'
import Menu from '../Menu'
import { Menu as MenuInf} from '@tilnet/lagaat-domain'
import { pagePadding } from '../../lib/tailwind/styles'

const ScrollToTop = dynamic(()=>import('../ScrollToTop'))

type HeaderProps = {
    menu: MenuInf,
}

const Header = ({menu}: HeaderProps) => {

    const menuRef = useRef<HTMLDivElement>(null)

    return (

        <div id="header" className={`2xl:max-w-screen-xl1/2 ${classnames(
            pagePadding, 
            "relative", 
            "sm:flex", 
            "sm:gap-9",
            "lg:gap-0",
            "sm:items-center",
            "lg:justify-between",
            "2xl:mx-auto",            
        )}`}>
            <div className="p-5 lg:px-0 lg:mr-0 lg:ml-6 w-52 sm:w-auto lg:w-48 xl:w-auto mx-auto">
                <Image key="logo" className="" src={logo} width={285} height={99} alt="logo" priority={true} />

            </div>
            <div ref={menuRef} className="absolute lg:relative right-0 top-0 left-0 bottom-0 flex items-center pr-5 sm:pr-6 md:pr-8 lg:pr-2 xl:pr-8">
                <Menu {...menu} />
            </div>
            <ScrollToTop menuRef={menuRef} />

        </div>
    )
}

export default Header