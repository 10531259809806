import Image from 'next/image'
import { classnames } from 'tailwindcss-classnames'

import { H2 } from '..'
import useReponsiveLayout from '../../lib/tailwind/useResponsiveLayout'
import reutImg from '../../../public/reut-round.png'

const About = ({niceToMeetText}: {niceToMeetText: string}) => {
    const {isMd} = useReponsiveLayout()
    return (
        <div id='about-me' className="flex flex-col md:flex-row gap-8 md:gap-0 md:items-center md:justify-between">
            <div className={classnames(
                "px-5", 
                "sm:px-6",
                "md:px-4",
                "xl:px-8",
                "bg-blue-400",
                "md:bg-white",
                "text-white",
                "md:text-black",
                "py-8",
                "md:w-1/2"
            )}>
                <H2 className={classnames("items-start")} title="נעים להכיר" decoColor={isMd ? "black" : "white"}/>
                {niceToMeetText &&
                    <div className="scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 pl-4 prose text-white md:text-black max-h-52 mt-8 lg:mt-12 overflow-y-scroll">
                        <div className="pl-8" dangerouslySetInnerHTML={{__html: niceToMeetText}} />
                    </div>
                }
            </div>
            <div className={classnames(
                "px-5", 
                "sm:px-6",
                "md:px-4",
                "xl:px-8",
                "text-center",
                "self-center",
                "md:w-1/2",
                "md:text-left",
                "lg:self-end",
                "max-w-md",
            )}>
                <Image src={reutImg} width={516} height={505} alt='reut' />
            </div>

        </div>
    )
}

export default About