const bounce = (fn: () => void | any, rate?: number):  ReturnType<typeof setTimeout> | null => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    const clearTimeoutFn = () => {
        if(timeoutId) {
            clearTimeout(timeoutId)
        }
    } 
    clearTimeoutFn()  
    timeoutId = setTimeout(fn, rate || 500)

    return timeoutId

}

export default bounce
