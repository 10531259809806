import { Post} from '@tilnet/lagaat-domain'
import Image from 'next/image'
import { classnames } from 'tailwindcss-classnames'
import { h3 } from '../../lib/tailwind/styles'

const PostItem = (post: Post) => {
    const { thumbnail, title, excerpt, link} = post
    return (
        <div className='mt-6 sm:w-3/6 sm:px-3 md:px-6 xl:px-8 lg:w-2/6'>
            {thumbnail && 
                <div className="flex justify-center">
                    <Image {...thumbnail} />
                </div>
            }
            { title &&
                <h3 className={classnames(
                    h3,
                    "py-4",
                    "mt-0",
                    "-mb-1",
                )}>{title}</h3>
            }
            {excerpt && 
                <p>{excerpt}</p>
            }
            <a target="_blank" rel="noreferrer" href={link ? link  : "#"} className="font-bold mt-2 mb-6 hover:text-orange-400">{"קרא.י עוד >"}</a>
        </div>
    )
}

export default PostItem