import {useEffect, useState, useCallback} from 'react'
import bounce from './bounce'

type ScrollPostions = {
    top: number, 
    bottom: number
} 

const useScrollPosition = (): ScrollPostions=> {
    
    const [position, setPosition] = useState<ScrollPostions>({
        top: 0,
        bottom: 0
    })

    const checkForWindow = 'undefined' === typeof window
        ?   undefined
        :   window

    const handleScroll = useCallback( 
        () => bounce(
            ()=>{
                setPosition({
                    top: checkForWindow
                        ? window.scrollY
                        : 0,
                    bottom: checkForWindow
                        ? document.body.offsetHeight - window.scrollY - window.outerHeight
                        : 0
                })
            }
        ),
        [checkForWindow]
    )       

    useEffect(
        ()=> {

            handleScroll()
            
            checkForWindow && window.addEventListener("scroll", handleScroll)

            return () => {
                window.removeEventListener("scroll", handleScroll)
            }
        },
        [checkForWindow, handleScroll]
    )
    return position
}

export default useScrollPosition